import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "card-header border-0 pt-5" }
const _hoisted_2 = { class: "card-title align-items-start flex-column" }
const _hoisted_3 = { class: "card-label fw-bolder fs-3 mb-1" }
const _hoisted_4 = { class: "card-toolbar" }
const _hoisted_5 = {
  type: "button",
  class: "btn btn-sm btn-icon btn-color-primary btn-active-light-primary",
  "data-kt-menu-trigger": "click",
  "data-kt-menu-placement": "bottom-end",
  "data-kt-menu-flip": "top-end"
}
const _hoisted_6 = { class: "svg-icon svg-icon-2" }
const _hoisted_7 = { class: "card-body py-3" }
const _hoisted_8 = { class: "table-responsive" }
const _hoisted_9 = { class: "table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3" }
const _hoisted_10 = { class: "fw-bolder text-muted" }
const _hoisted_11 = { class: "min-w-140px" }
const _hoisted_12 = { class: "min-w-140px" }
const _hoisted_13 = { class: "min-w-140px" }
const _hoisted_14 = { class: "text-dark fw-bolder text-hover-primary fs-6" }
const _hoisted_15 = { class: "text-dark fw-bolder text-hover-primary fs-6" }
const _hoisted_16 = { class: "text-dark fw-bolder text-hover-primary fs-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")
  const _component_Dropdown2 = _resolveComponent("Dropdown2")

  return (_openBlock(), _createBlock("div", {
    class: [_ctx.widgetClasses, "card"]
  }, [
    _createVNode("div", _hoisted_1, [
      _createVNode("h3", _hoisted_2, [
        _createVNode("span", _hoisted_3, _toDisplayString(_ctx.translate("clients")), 1)
      ]),
      _createVNode("div", _hoisted_4, [
        _createVNode("button", _hoisted_5, [
          _createVNode("span", _hoisted_6, [
            _createVNode(_component_inline_svg, { src: "media/icons/duotone/Layout/Layout-4-blocks-2.svg" })
          ])
        ]),
        _createVNode(_component_Dropdown2)
      ])
    ]),
    _createVNode("div", _hoisted_7, [
      _createVNode("div", _hoisted_8, [
        _createVNode("table", _hoisted_9, [
          _createVNode("thead", null, [
            _createVNode("tr", _hoisted_10, [
              _createVNode("th", _hoisted_11, _toDisplayString(_ctx.translate("name")), 1),
              _createVNode("th", _hoisted_12, _toDisplayString(_ctx.translate("email")), 1),
              _createVNode("th", _hoisted_13, _toDisplayString(_ctx.translate("apiToken")), 1)
            ])
          ]),
          _createVNode("tbody", null, [
            (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.list, (item, index) => {
              return (_openBlock(), _createBlock("tr", { key: index }, [
                _createVNode("td", null, [
                  _createVNode("a", _hoisted_14, _toDisplayString(item["name"]), 1)
                ]),
                _createVNode("td", null, [
                  _createVNode("a", _hoisted_15, _toDisplayString(item["email"]), 1)
                ]),
                _createVNode("td", null, [
                  _createVNode("a", _hoisted_16, _toDisplayString(item["apiToken"]), 1)
                ])
              ]))
            }), 128))
          ])
        ])
      ])
    ])
  ], 2))
}